import * as React from "react"
import { graphql, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../layouts/default"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import Text from "../../components/text"

function VideoIndex({ data }) {
  return (
    <Layout backdrop="expertinnen">
      <Seo
        title="Expert*innen Videos"
        description="Expert*innen aus der Kunst und Kultur veranschaulichen die Machart und Wirkung von Hölderlins Werken sowie die zeitgeschichtlichen Ereignisse, vor denen sie entstanden sind."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Turm-TV",
                link: "/turm-tv",
              },
              {
                title: "Expert*innen Videos",
                link: "/videos",
              },
            ]}
          />
          <PageTitle>Expert*innen Videos</PageTitle>
          <Paragraph dropcap={true}>
            Expert*innen aus der Kunst und Kultur veranschaulichen die Machart
            und Wirkung von Hölderlins Werken sowie die zeitgeschichtlichen
            Ereignisse, vor denen sie entstanden sind. Was macht Hölderlins
            Literatur so besonders? Wie und mit welchen Kniffen hat der Dichter
            dazu beigetragen? Und welche Effekte hat er dadurch erzielt?
          </Paragraph>
        </Stack>
        <Stack as="ul" space={12}>
          {data.videos.nodes.map((video) => (
            <Link to={video.nameSlug} key={video.meta.id}>
              <Box
                sx={{
                  overflow: "hidden",
                  ":hover .video-play-button, :focus .video-play-button": {
                    bg: "white",
                    color: "black",
                  },
                  ":hover .video-heading, :focus .video-heading": {
                    textDecoration: "underline",
                  },
                }}
              >
                <Columns collapse={[true, false]} space={[6, 12]}>
                  <Column width={["full", "1/2"]}>
                    <Box sx={{ position: "relative" }}>
                      <GatsbyImage
                        image={video.meta.cover.childImageSharp.gatsbyImageData}
                        alt={`Titelbild Video ${video.name}`}
                      />
                      <Box
                        className="video-play-button"
                        sx={{
                          width: [12, 12, 16],
                          height: [12, 12, 16],
                          borderRadius: "100%",
                          bg: "black",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Icon icon="play" size={[6, 6, 8]} />
                      </Box>
                    </Box>
                  </Column>
                  <Column>
                    <Stack space={3}>
                      <Heading className="video-heading" as="h2" level={4}>
                        {video.name}
                      </Heading>
                      <Text size={[2, 3]}>{video.description}</Text>
                    </Stack>
                  </Column>
                </Columns>
              </Box>
            </Link>
          ))}
        </Stack>
      </Stack>
    </Layout>
  )
}

export default VideoIndex

export const query = graphql`
  {
    videos: allVideo(sort: { fields: meta___id }) {
      nodes {
        name
        nameSlug: gatsbyPath(filePath: "/videos/{Video.name}")
        description
        meta {
          id
          cover {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
          src
        }
      }
    }
    ogImage: file(relativePath: { eq: "og_images/videos.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1200, height: 600, layout: FIXED)
      }
    }
  }
`
